// src/data/servicesData.js
import { Text, Flex, List, ListItem } from "@chakra-ui/react";
import { textBlack } from "../utils/colors";

export const services = [
  {
    id: 1,
    title: "Strategy",
    color: "#3be898",
    text: (
      <>
        <Text as="p">
          Setting and communicating your business strategy is crucial to
          success, providing a clear framework and direction that underpins all
          future initiatives. In the fast-paced world of short-term rentals,
          strategy must be adaptive, evolving to stay aligned with your dynamic
          goals.
        </Text>
        <Text as="p" mt={4}>
          Our approach involves turning your vision into actionable, profitable
          plans that synchronize with your broader business objectives. We
          pinpoint the crucial steps needed to achieve your goals, whether it’s
          maximizing occupancy rates, enhancing guest satisfaction, or
          optimizing revenue streams. Collaboratively, we tailor an operating
          model that not only reflects your business’s unique culture but also
          strategically recruits the right talent and implements the necessary
          systems to achieve your aims.
        </Text>
        <Text as="p" mt={4}>
          As we translate your strategy into practical, measurable
          actions—including detailed roadmaps and customized projects—we ensure
          holistic alignment across your entire operation. This includes
          streamlining business processes, leveraging data insights, integrating
          technology, and optimizing systems to guarantee the delivery of
          promised benefits and maximize the return on your investments.
        </Text>
        <Text as="p" mt={4}>
          Our support doesn’t stop at implementation. We monitor outcomes,
          navigate industry shifts, and adapt strategies as needed to keep you
          on target with your objectives. Effective communication is the
          cornerstone of our approach, linking every aspect of your strategy to
          your broader business aims and engaging all stakeholders in a unified
          journey toward shared success. Just as in our M&A strategy, where we
          emphasize risk reduction and opportunity maximization, our
          implementation of business strategies focuses on leveraging innovation
          and driving transformation within the short-term rental industry.
        </Text>
      </>
    ),
    capabilities: (
      <>
        <Flex direction="column">
          <Text
            fontSize="0.8rem"
            color={textBlack}
            opacity="0.33"
            fontWeight="500"
            textTransform="uppercase"
            letterSpacing="0.1px"
            fontFamily="DM Mono"
            m="0 0 0.25rem"
          >
            Including
          </Text>
          <List>
            <ListItem m="0 0 0.25rem">
              <Text
                fontSize="0.8rem"
                color={textBlack}
                fontWeight="500"
                textTransform="uppercase"
                letterSpacing="0.1px"
                fontFamily="DM Mono"
              >
                Digital Marketing
              </Text>
            </ListItem>
            <ListItem m="0 0 0.25rem">
              <Text
                fontSize="0.8rem"
                color={textBlack}
                fontWeight="500"
                textTransform="uppercase"
                letterSpacing="0.1px"
                fontFamily="DM Mono"
              >
                Target Operating Model
              </Text>
            </ListItem>
            <ListItem m="0 0 0.25rem">
              <Text
                fontSize="0.8rem"
                color={textBlack}
                fontWeight="500"
                textTransform="uppercase"
                letterSpacing="0.1px"
                fontFamily="DM Mono"
              >
                Data Analytics
              </Text>
            </ListItem>
            <ListItem m="0 0 0.25rem">
              <Text
                fontSize="0.8rem"
                color={textBlack}
                fontWeight="500"
                textTransform="uppercase"
                letterSpacing="0.1px"
                fontFamily="DM Mono"
              >
                Automations
              </Text>
            </ListItem>

            <ListItem m="0 0 0.25rem">
              <Text
                fontSize="0.8rem"
                color={textBlack}
                fontWeight="500"
                textTransform="uppercase"
                letterSpacing="0.1px"
                fontFamily="DM Mono"
              >
                AI Implementation
              </Text>
            </ListItem>

            <ListItem m="0 0 0.25rem">
              <Text
                fontSize="0.8rem"
                color={textBlack}
                fontWeight="500"
                textTransform="uppercase"
                letterSpacing="0.1px"
                fontFamily="DM Mono"
              >
                Horizon Planning
              </Text>
            </ListItem>

            <ListItem m="0 0 0.25rem">
              <Text
                fontSize="0.8rem"
                color={textBlack}
                fontWeight="500"
                textTransform="uppercase"
                letterSpacing="0.1px"
                fontFamily="DM Mono"
              >
                + Many more
              </Text>
            </ListItem>
          </List>
        </Flex>
      </>
    ),
  },
  {
    id: 2,
    title: "Transformation",
    color: "#fece00", // Adjust the color as needed
    text: (
      <>
        <Text as="p">
          In the competitive landscape of short-term rentals, transformation is
          not just about change—it's about evolving your business to stay ahead
          of the curve. Whether you're scaling your operations, enhancing guest
          experiences, or adopting new technologies, transformation is essential
          to maintaining your competitive edge.
        </Text>
        <Text as="p" mt={4}>
          Our approach to transformation begins with a deep understanding of
          your business's current state and its unique challenges. We work
          closely with you to identify opportunities for improvement, whether
          that’s through streamlining operations, integrating cutting-edge
          technology, or enhancing customer engagement. We focus on creating a
          transformation roadmap that aligns with your strategic goals, ensuring
          that every change is purposeful and drives value.
        </Text>
        <Text as="p" mt={4}>
          As we implement these transformative changes, we ensure that they are
          seamlessly integrated into your existing operations. This involves
          optimizing processes, upgrading technology systems, and enhancing team
          capabilities to deliver superior performance. Our team is committed to
          guiding you through each phase of the transformation, providing the
          expertise and support needed to overcome obstacles and achieve
          sustainable results.
        </Text>
        <Text as="p" mt={4}>
          Our transformation services don’t stop at execution. We continually
          monitor the impact of the changes, making adjustments as necessary to
          respond to industry shifts, emerging trends, and new opportunities.
          Effective transformation requires agility, and we ensure that your
          business remains flexible and responsive throughout the process.
        </Text>
        <Text as="p" mt={4}>
          Communication is key in any transformation effort. We work with all
          stakeholders to ensure that the transformation is understood and
          embraced across your organization, fostering a culture that supports
          ongoing innovation and improvement. Just as with our strategic
          services, our transformation initiatives are designed to maximize
          benefits, reduce risks, and position your business for long-term
          success in the short-term rental market.
        </Text>
      </>
    ),
    capabilities: (
      <>
        <Flex direction="column">
          <Text
            fontSize="0.8rem"
            color={textBlack}
            opacity="0.33"
            fontWeight="500"
            textTransform="uppercase"
            letterSpacing="0.1px"
            fontFamily="DM Mono"
            m="0 0 0.25rem"
          >
            Including
          </Text>
          <List>
            <ListItem m="0 0 0.25rem">
              <Text
                fontSize="0.8rem"
                color={textBlack}
                fontWeight="500"
                textTransform="uppercase"
                letterSpacing="0.1px"
                fontFamily="DM Mono"
              >
                Operational Efficiency
              </Text>
            </ListItem>
            <ListItem m="0 0 0.25rem">
              <Text
                fontSize="0.8rem"
                color={textBlack}
                fontWeight="500"
                textTransform="uppercase"
                letterSpacing="0.1px"
                fontFamily="DM Mono"
              >
                Technology Integration
              </Text>
            </ListItem>
            <ListItem m="0 0 0.25rem">
              <Text
                fontSize="0.8rem"
                color={textBlack}
                fontWeight="500"
                textTransform="uppercase"
                letterSpacing="0.1px"
                fontFamily="DM Mono"
              >
                Customer Experience Enhancement
              </Text>
            </ListItem>
            <ListItem m="0 0 0.25rem">
              <Text
                fontSize="0.8rem"
                color={textBlack}
                fontWeight="500"
                textTransform="uppercase"
                letterSpacing="0.1px"
                fontFamily="DM Mono"
              >
                Process Reengineering
              </Text>
            </ListItem>
            <ListItem m="0 0 0.25rem">
              <Text
                fontSize="0.8rem"
                color={textBlack}
                fontWeight="500"
                textTransform="uppercase"
                letterSpacing="0.1px"
                fontFamily="DM Mono"
              >
                Workforce Development
              </Text>
            </ListItem>
            <ListItem m="0 0 0.25rem">
              <Text
                fontSize="0.8rem"
                color={textBlack}
                fontWeight="500"
                textTransform="uppercase"
                letterSpacing="0.1px"
                fontFamily="DM Mono"
              >
                Change Management
              </Text>
            </ListItem>
            <ListItem m="0 0 0.25rem">
              <Text
                fontSize="0.8rem"
                color={textBlack}
                fontWeight="500"
                textTransform="uppercase"
                letterSpacing="0.1px"
                fontFamily="DM Mono"
              >
                + Many more
              </Text>
            </ListItem>
          </List>
        </Flex>
      </>
    ),
  },

  {
    id: 3,
    title: "Automation",
    color: "#FFA07A", // Example color, adjust as needed
    text: (
      <>
        <Text as="p">
          Automation is the key to scaling your short-term rental business
          efficiently while maintaining high standards of service. By automating
          routine tasks, you can focus on what matters most—delivering
          exceptional guest experiences and growing your business. In a
          fast-paced industry like short-term rentals, automation allows you to
          streamline operations, reduce manual workload, and improve accuracy,
          leading to better outcomes and increased profitability.
        </Text>
        <Text as="p" mt={4}>
          Our approach to automation starts with identifying the areas of your
          business that can benefit the most from automated solutions. Whether
          it's automating booking processes, guest communications, pricing
          adjustments, or property management tasks, we ensure that the
          automation solutions we implement are tailored to your specific needs.
          We focus on integrating these solutions seamlessly into your existing
          systems, ensuring minimal disruption and maximum efficiency.
        </Text>
        <Text as="p" mt={4}>
          As we implement automation, we ensure that all processes are optimized
          to work harmoniously, reducing errors and enhancing consistency across
          your operations. From automated booking confirmations to dynamic
          pricing models, our solutions are designed to save you time, reduce
          costs, and increase your revenue potential. We leverage the latest in
          AI and machine learning technologies to provide intelligent automation
          that not only handles routine tasks but also adapts to changing
          conditions and opportunities.
        </Text>
        <Text as="p" mt={4}>
          Our support goes beyond just setting up automated systems. We
          continually monitor their performance, making adjustments as needed to
          ensure they remain effective and aligned with your business goals.
          Automation is not a one-time solution but an ongoing process of
          refinement and improvement, ensuring that your business stays
          competitive in an evolving market.
        </Text>
        <Text as="p" mt={4}>
          Effective communication and training are essential to successful
          automation. We work closely with your team to ensure they understand
          and can fully leverage the new systems, fostering a culture of
          innovation and continuous improvement. Just as with our other
          services, our automation initiatives are designed to maximize
          efficiency, enhance the guest experience, and drive long-term success
          for your short-term rental business.
        </Text>
      </>
    ),
    capabilities: (
      <>
        <Flex direction="column">
          <Text
            fontSize="0.8rem"
            color={textBlack}
            opacity="0.33"
            fontWeight="500"
            textTransform="uppercase"
            letterSpacing="0.1px"
            fontFamily="DM Mono"
            m="0 0 0.25rem"
          >
            Including
          </Text>
          <List>
            <ListItem m="0 0 0.25rem">
              <Text
                fontSize="0.8rem"
                color={textBlack}
                fontWeight="500"
                textTransform="uppercase"
                letterSpacing="0.1px"
                fontFamily="DM Mono"
              >
                Booking Automation
              </Text>
            </ListItem>
            <ListItem m="0 0 0.25rem">
              <Text
                fontSize="0.8rem"
                color={textBlack}
                fontWeight="500"
                textTransform="uppercase"
                letterSpacing="0.1px"
                fontFamily="DM Mono"
              >
                Guest Communication
              </Text>
            </ListItem>
            <ListItem m="0 0 0.25rem">
              <Text
                fontSize="0.8rem"
                color={textBlack}
                fontWeight="500"
                textTransform="uppercase"
                letterSpacing="0.1px"
                fontFamily="DM Mono"
              >
                Dynamic Pricing
              </Text>
            </ListItem>
            <ListItem m="0 0 0.25rem">
              <Text
                fontSize="0.8rem"
                color={textBlack}
                fontWeight="500"
                textTransform="uppercase"
                letterSpacing="0.1px"
                fontFamily="DM Mono"
              >
                Property Management Automation
              </Text>
            </ListItem>

            <ListItem m="0 0 0.25rem">
              <Text
                fontSize="0.8rem"
                color={textBlack}
                fontWeight="500"
                textTransform="uppercase"
                letterSpacing="0.1px"
                fontFamily="DM Mono"
              >
                AI-Powered Solutions
              </Text>
            </ListItem>

            <ListItem m="0 0 0.25rem">
              <Text
                fontSize="0.8rem"
                color={textBlack}
                fontWeight="500"
                textTransform="uppercase"
                letterSpacing="0.1px"
                fontFamily="DM Mono"
              >
                Task Scheduling
              </Text>
            </ListItem>

            <ListItem m="0 0 0.25rem">
              <Text
                fontSize="0.8rem"
                color={textBlack}
                fontWeight="500"
                textTransform="uppercase"
                letterSpacing="0.1px"
                fontFamily="DM Mono"
              >
                + Many more
              </Text>
            </ListItem>
          </List>
        </Flex>
      </>
    ),
  },

  {
    id: 4,
    title: "Optimization",
    color: "#6A5ACD", // Example color, adjust as needed
    text: (
      <>
        <Text as="p">
          In the competitive world of short-term rentals, optimization is
          essential to maximize your business’s potential. Optimization involves
          refining every aspect of your operations, from pricing strategies to
          guest services, to ensure that your business runs at peak efficiency
          and profitability. It’s about making the most of your resources and
          turning potential challenges into opportunities for growth.
        </Text>
        <Text as="p" mt={4}>
          Our approach to optimization begins with a comprehensive analysis of
          your current operations. We identify areas where improvements can be
          made, whether it’s through enhancing guest satisfaction, reducing
          costs, or increasing occupancy rates. By leveraging data-driven
          insights and industry best practices, we develop customized
          optimization strategies that align with your overall business
          objectives.
        </Text>
        <Text as="p" mt={4}>
          As we implement these strategies, we focus on fine-tuning your
          processes to ensure they deliver the maximum possible benefit. This
          could involve optimizing your pricing models to reflect market
          conditions, streamlining your booking processes, or improving property
          management practices. Every change is made with the goal of increasing
          efficiency, boosting revenue, and enhancing the overall guest
          experience.
        </Text>
        <Text as="p" mt={4}>
          Our optimization services don’t just stop at implementation. We
          continuously monitor performance metrics to ensure that the changes
          are having the desired impact. We also remain agile, making
          adjustments as necessary to respond to evolving market conditions or
          new business goals. Optimization is an ongoing process, and we are
          committed to helping your business stay ahead of the competition.
        </Text>
        <Text as="p" mt={4}>
          Effective optimization requires clear communication and stakeholder
          involvement. We work closely with your team to ensure that everyone is
          aligned with the optimization goals and understands their role in
          achieving them. Just as with our other services, our optimization
          efforts are designed to deliver measurable results, helping your
          short-term rental business achieve sustained success and growth.
        </Text>
      </>
    ),
    capabilities: (
      <>
        <Flex direction="column">
          <Text
            fontSize="0.8rem"
            color={textBlack}
            opacity="0.33"
            fontWeight="500"
            textTransform="uppercase"
            letterSpacing="0.1px"
            fontFamily="DM Mono"
            m="0 0 0.25rem"
          >
            Including
          </Text>
          <List>
            <ListItem m="0 0 0.25rem">
              <Text
                fontSize="0.8rem"
                color={textBlack}
                fontWeight="500"
                textTransform="uppercase"
                letterSpacing="0.1px"
                fontFamily="DM Mono"
              >
                Pricing Optimization
              </Text>
            </ListItem>
            <ListItem m="0 0 0.25rem">
              <Text
                fontSize="0.8rem"
                color={textBlack}
                fontWeight="500"
                textTransform="uppercase"
                letterSpacing="0.1px"
                fontFamily="DM Mono"
              >
                Guest Satisfaction Improvement
              </Text>
            </ListItem>
            <ListItem m="0 0 0.25rem">
              <Text
                fontSize="0.8rem"
                color={textBlack}
                fontWeight="500"
                textTransform="uppercase"
                letterSpacing="0.1px"
                fontFamily="DM Mono"
              >
                Operational Efficiency
              </Text>
            </ListItem>
            <ListItem m="0 0 0.25rem">
              <Text
                fontSize="0.8rem"
                color={textBlack}
                fontWeight="500"
                textTransform="uppercase"
                letterSpacing="0.1px"
                fontFamily="DM Mono"
              >
                Cost Reduction
              </Text>
            </ListItem>

            <ListItem m="0 0 0.25rem">
              <Text
                fontSize="0.8rem"
                color={textBlack}
                fontWeight="500"
                textTransform="uppercase"
                letterSpacing="0.1px"
                fontFamily="DM Mono"
              >
                Property Performance Enhancement
              </Text>
            </ListItem>

            <ListItem m="0 0 0.25rem">
              <Text
                fontSize="0.8rem"
                color={textBlack}
                fontWeight="500"
                textTransform="uppercase"
                letterSpacing="0.1px"
                fontFamily="DM Mono"
              >
                Revenue Management
              </Text>
            </ListItem>

            <ListItem m="0 0 0.25rem">
              <Text
                fontSize="0.8rem"
                color={textBlack}
                fontWeight="500"
                textTransform="uppercase"
                letterSpacing="0.1px"
                fontFamily="DM Mono"
              >
                + Many more
              </Text>
            </ListItem>
          </List>
        </Flex>
      </>
    ),
  },

  {
    id: 5,
    title: "Legal",
    color: "#FF6347", // Example color, adjust as needed
    text: (
      <>
        <Text as="p">
          Navigating the legal landscape in the short-term rental industry can
          be complex and challenging. From compliance with local regulations to
          managing contracts and protecting your assets, having a robust legal
          strategy is crucial to safeguarding your business and ensuring its
          long-term success. At [Your Company Name], we provide comprehensive
          legal support tailored specifically to the needs of short-term rental
          businesses.
        </Text>
        <Text as="p" mt={4}>
          Our approach to legal services begins with a thorough assessment of
          your business’s current legal standing. We work closely with you to
          identify potential risks, ensure compliance with all relevant laws and
          regulations, and establish a strong legal foundation for your
          operations. Whether you're dealing with zoning laws, rental
          agreements, or liability concerns, our team provides expert guidance
          to help you navigate these challenges with confidence.
        </Text>
        <Text as="p" mt={4}>
          As we implement your legal strategy, we focus on protecting your
          business from potential legal issues while also enabling you to take
          advantage of growth opportunities. This includes drafting and
          reviewing contracts, managing disputes, and advising on risk
          management practices. Our goal is to minimize your legal risks while
          maximizing your operational flexibility and profitability.
        </Text>
        <Text as="p" mt={4}>
          Our legal services extend beyond just compliance. We actively monitor
          changes in laws and regulations that could impact your business,
          ensuring that you remain informed and prepared to adapt as needed.
          This proactive approach helps you avoid legal pitfalls and stay ahead
          of industry developments.
        </Text>
        <Text as="p" mt={4}>
          Effective legal management is also about communication and
          transparency. We ensure that you and your team are fully informed
          about your legal obligations and rights, and we work with you to
          develop strategies that align with your business goals. Just as in our
          other service areas, our legal strategies are designed to reduce risk,
          protect your assets, and support the sustained growth and success of
          your short-term rental business.
        </Text>
      </>
    ),
    capabilities: (
      <>
        <Flex direction="column">
          <Text
            fontSize="0.8rem"
            color={textBlack}
            opacity="0.33"
            fontWeight="500"
            textTransform="uppercase"
            letterSpacing="0.1px"
            fontFamily="DM Mono"
            m="0 0 0.25rem"
          >
            Including
          </Text>
          <List>
            <ListItem m="0 0 0.25rem">
              <Text
                fontSize="0.8rem"
                color={textBlack}
                fontWeight="500"
                textTransform="uppercase"
                letterSpacing="0.1px"
                fontFamily="DM Mono"
              >
                Compliance Management
              </Text>
            </ListItem>
            <ListItem m="0 0 0.25rem">
              <Text
                fontSize="0.8rem"
                color={textBlack}
                fontWeight="500"
                textTransform="uppercase"
                letterSpacing="0.1px"
                fontFamily="DM Mono"
              >
                Contract Drafting and Review
              </Text>
            </ListItem>
            <ListItem m="0 0 0.25rem">
              <Text
                fontSize="0.8rem"
                color={textBlack}
                fontWeight="500"
                textTransform="uppercase"
                letterSpacing="0.1px"
                fontFamily="DM Mono"
              >
                Risk Management
              </Text>
            </ListItem>
            <ListItem m="0 0 0.25rem">
              <Text
                fontSize="0.8rem"
                color={textBlack}
                fontWeight="500"
                textTransform="uppercase"
                letterSpacing="0.1px"
                fontFamily="DM Mono"
              >
                Liability Protection
              </Text>
            </ListItem>

            <ListItem m="0 0 0.25rem">
              <Text
                fontSize="0.8rem"
                color={textBlack}
                fontWeight="500"
                textTransform="uppercase"
                letterSpacing="0.1px"
                fontFamily="DM Mono"
              >
                Zoning and Regulation Guidance
              </Text>
            </ListItem>

            <ListItem m="0 0 0.25rem">
              <Text
                fontSize="0.8rem"
                color={textBlack}
                fontWeight="500"
                textTransform="uppercase"
                letterSpacing="0.1px"
                fontFamily="DM Mono"
              >
                Dispute Resolution
              </Text>
            </ListItem>

            <ListItem m="0 0 0.25rem">
              <Text
                fontSize="0.8rem"
                color={textBlack}
                fontWeight="500"
                textTransform="uppercase"
                letterSpacing="0.1px"
                fontFamily="DM Mono"
              >
                + Many more
              </Text>
            </ListItem>
          </List>
        </Flex>
      </>
    ),
  },

  {
    id: 6,
    title: "Emerging Trends",
    color: "#20B2AA", // Example color, adjust as needed
    text: (
      <>
        <Text as="p">
          The short-term rental industry is constantly evolving, with new trends
          and innovations reshaping the way businesses operate and compete.
          Staying ahead of these emerging trends is crucial to maintaining your
          competitive edge and ensuring that your business remains relevant in a
          rapidly changing market. At STR Maven, we provide insights and
          strategies that help you capitalize on the latest trends and position
          your business for future success.
        </Text>
        <Text as="p" mt={4}>
          Our approach to navigating emerging trends begins with in-depth market
          research and analysis. We continuously monitor the industry for new
          developments, from technological advancements to shifts in consumer
          behavior, and assess their potential impact on your business. Whether
          it’s the rise of eco-friendly accommodations, the integration of smart
          home technologies, or changing guest expectations, we help you
          understand these trends and adapt accordingly.
        </Text>
        <Text as="p" mt={4}>
          As we implement strategies to leverage emerging trends, we focus on
          aligning these innovations with your business goals. This could
          involve adopting new technologies to enhance the guest experience,
          developing sustainable practices that appeal to modern travelers, or
          exploring new revenue streams that capitalize on market shifts. Our
          goal is to ensure that your business not only keeps pace with industry
          changes but also leads the way in adopting new and profitable
          practices.
        </Text>
        <Text as="p" mt={4}>
          Our services extend beyond simply identifying trends. We work with you
          to integrate these trends into your overall business strategy,
          ensuring that they are implemented effectively and deliver tangible
          results. This includes training your team, updating processes, and
          communicating changes to stakeholders, so everyone is aligned and
          ready to embrace new opportunities.
        </Text>
        <Text as="p" mt={4}>
          Effective trend management is about foresight and agility. We help you
          anticipate what’s next in the short-term rental industry and prepare
          your business to respond swiftly and strategically. Just as with our
          other services, our focus on emerging trends is designed to give your
          business a competitive advantage, drive innovation, and ensure
          long-term success.
        </Text>
      </>
    ),
    capabilities: (
      <>
        <Flex direction="column">
          <Text
            fontSize="0.8rem"
            color={textBlack}
            opacity="0.33"
            fontWeight="500"
            textTransform="uppercase"
            letterSpacing="0.1px"
            fontFamily="DM Mono"
            m="0 0 0.25rem"
          >
            Including
          </Text>
          <List>
            <ListItem m="0 0 0.25rem">
              <Text
                fontSize="0.8rem"
                color={textBlack}
                fontWeight="500"
                textTransform="uppercase"
                letterSpacing="0.1px"
                fontFamily="DM Mono"
              >
                Technology Integration
              </Text>
            </ListItem>
            <ListItem m="0 0 0.25rem">
              <Text
                fontSize="0.8rem"
                color={textBlack}
                fontWeight="500"
                textTransform="uppercase"
                letterSpacing="0.1px"
                fontFamily="DM Mono"
              >
                Sustainability Practices
              </Text>
            </ListItem>
            <ListItem m="0 0 0.25rem">
              <Text
                fontSize="0.8rem"
                color={textBlack}
                fontWeight="500"
                textTransform="uppercase"
                letterSpacing="0.1px"
                fontFamily="DM Mono"
              >
                Smart Home Solutions
              </Text>
            </ListItem>
            <ListItem m="0 0 0.25rem">
              <Text
                fontSize="0.8rem"
                color={textBlack}
                fontWeight="500"
                textTransform="uppercase"
                letterSpacing="0.1px"
                fontFamily="DM Mono"
              >
                Market Shifts Analysis
              </Text>
            </ListItem>

            <ListItem m="0 0 0.25rem">
              <Text
                fontSize="0.8rem"
                color={textBlack}
                fontWeight="500"
                textTransform="uppercase"
                letterSpacing="0.1px"
                fontFamily="DM Mono"
              >
                Consumer Behavior Insights
              </Text>
            </ListItem>

            <ListItem m="0 0 0.25rem">
              <Text
                fontSize="0.8rem"
                color={textBlack}
                fontWeight="500"
                textTransform="uppercase"
                letterSpacing="0.1px"
                fontFamily="DM Mono"
              >
                Revenue Diversification
              </Text>
            </ListItem>

            <ListItem m="0 0 0.25rem">
              <Text
                fontSize="0.8rem"
                color={textBlack}
                fontWeight="500"
                textTransform="uppercase"
                letterSpacing="0.1px"
                fontFamily="DM Mono"
              >
                + Many more
              </Text>
            </ListItem>
          </List>
        </Flex>
      </>
    ),
  },

  {
    id: 7,
    title: "M&A Advisory",
    color: "#8A2BE2", // Example color, adjust as needed
    text: (
      <>
        <Text as="p">
          In the dynamic short-term rental industry, mergers and acquisitions
          (M&A) present unique opportunities for growth, expansion, and
          increased market share. However, navigating the complexities of M&A
          transactions requires expert guidance to ensure that your business
          makes the most of these opportunities while minimizing risks. Our M&A
          advisory services are tailored to help you successfully navigate every
          stage of the M&A process, from strategy formulation to
          post-transaction integration.
        </Text>
        <Text as="p" mt={4}>
          Our approach to M&A advisory begins with a thorough understanding of
          your business’s objectives and strategic goals. We work closely with
          you to identify potential acquisition targets or merger opportunities
          that align with your vision for growth. Whether you’re looking to
          expand your portfolio, enter new markets, or enhance your operational
          capabilities, we provide the insights and strategies needed to
          identify the right opportunities.
        </Text>
        <Text as="p" mt={4}>
          As we move forward with the M&A process, we focus on conducting
          comprehensive due diligence to ensure that all aspects of the
          transaction are thoroughly evaluated. This includes assessing the
          financial health of potential targets, analyzing operational
          synergies, and identifying any potential risks. Our goal is to provide
          you with a clear and accurate picture of the opportunities and
          challenges associated with each transaction.
        </Text>
        <Text as="p" mt={4}>
          Once a deal is in place, our support extends to the critical phase of
          integration. We work with you to ensure that the merger or acquisition
          is seamlessly integrated into your existing operations, with a focus
          on maximizing synergies, retaining key talent, and maintaining
          business continuity. Our expertise in change management helps smooth
          the transition and ensures that the combined entity is well-positioned
          for success.
        </Text>
        <Text as="p" mt={4}>
          Our M&A advisory services also include strategic communication,
          ensuring that all stakeholders are informed and engaged throughout the
          process. We help you develop a clear and compelling narrative that
          aligns with your broader business goals and fosters confidence among
          investors, employees, and customers.
        </Text>
        <Text as="p" mt={4}>
          Just as with our other services, our M&A advisory is designed to
          deliver measurable results, reduce risks, and create value for your
          business. Whether you’re considering a strategic acquisition, a
          merger, or divestiture, our team is here to guide you through every
          step of the process, ensuring that your business emerges stronger and
          more competitive in the short-term rental market.
        </Text>
      </>
    ),
    capabilities: (
      <>
        <Flex direction="column">
          <Text
            fontSize="0.8rem"
            color={textBlack}
            opacity="0.33"
            fontWeight="500"
            textTransform="uppercase"
            letterSpacing="0.1px"
            fontFamily="DM Mono"
            m="0 0 0.25rem"
          >
            Including
          </Text>
          <List>
            <ListItem m="0 0 0.25rem">
              <Text
                fontSize="0.8rem"
                color={textBlack}
                fontWeight="500"
                textTransform="uppercase"
                letterSpacing="0.1px"
                fontFamily="DM Mono"
              >
                Strategic Planning
              </Text>
            </ListItem>
            <ListItem m="0 0 0.25rem">
              <Text
                fontSize="0.8rem"
                color={textBlack}
                fontWeight="500"
                textTransform="uppercase"
                letterSpacing="0.1px"
                fontFamily="DM Mono"
              >
                Target Identification
              </Text>
            </ListItem>
            <ListItem m="0 0 0.25rem">
              <Text
                fontSize="0.8rem"
                color={textBlack}
                fontWeight="500"
                textTransform="uppercase"
                letterSpacing="0.1px"
                fontFamily="DM Mono"
              >
                Due Diligence
              </Text>
            </ListItem>
            <ListItem m="0 0 0.25rem">
              <Text
                fontSize="0.8rem"
                color={textBlack}
                fontWeight="500"
                textTransform="uppercase"
                letterSpacing="0.1px"
                fontFamily="DM Mono"
              >
                Risk Management
              </Text>
            </ListItem>

            <ListItem m="0 0 0.25rem">
              <Text
                fontSize="0.8rem"
                color={textBlack}
                fontWeight="500"
                textTransform="uppercase"
                letterSpacing="0.1px"
                fontFamily="DM Mono"
              >
                Transaction Structuring
              </Text>
            </ListItem>

            <ListItem m="0 0 0.25rem">
              <Text
                fontSize="0.8rem"
                color={textBlack}
                fontWeight="500"
                textTransform="uppercase"
                letterSpacing="0.1px"
                fontFamily="DM Mono"
              >
                Post-Merger Integration
              </Text>
            </ListItem>

            <ListItem m="0 0 0.25rem">
              <Text
                fontSize="0.8rem"
                color={textBlack}
                fontWeight="500"
                textTransform="uppercase"
                letterSpacing="0.1px"
                fontFamily="DM Mono"
              >
                + Many more
              </Text>
            </ListItem>
          </List>
        </Flex>
      </>
    ),
  },

  {
    id: 8,
    title: "Other",
    color: "#FFB6C1", // Example color, adjust as needed
    text: (
      <>
        <Text as="p">
          Every short-term rental business is unique, and sometimes, your needs
          extend beyond traditional categories of service. Whether you’re facing
          a specific challenge, seeking a bespoke solution, or exploring new
          opportunities, STR Maven offers a range of specialized services
          designed to meet your unique requirements. Our expertise goes beyond
          the standard offerings, allowing us to provide tailored support that
          addresses the distinct aspects of your business.
        </Text>
        <Text as="p" mt={4}>
          Our approach to these specialized services starts with a deep dive
          into your business’s specific circumstances. We listen carefully to
          your needs, challenges, and goals, and then craft a custom strategy
          that aligns with your broader objectives. Whether it’s a niche market
          analysis, custom software development, or specialized training for
          your team, we ensure that our solutions are precisely tailored to
          deliver the results you’re looking for.
        </Text>
        <Text as="p" mt={4}>
          As we develop and implement these custom solutions, we focus on
          ensuring that they integrate seamlessly with your existing operations.
          Our goal is to enhance your business’s performance and position you
          for long-term success, regardless of the complexity or uniqueness of
          your needs.
        </Text>
        <Text as="p" mt={4}>
          Our “Other” services are as varied as the businesses we work with.
          From creative marketing strategies to specialized compliance
          assistance, we bring the same level of expertise, dedication, and
          attention to detail to every project. We’re here to help you tackle
          the challenges that don’t fit neatly into a box, offering innovative
          solutions that drive real results.
        </Text>
        <Text as="p" mt={4}>
          We also recognize that flexibility is key in a fast-paced industry
          like short-term rentals. That’s why we’re always ready to adapt our
          services to meet your evolving needs, providing ongoing support as
          your business grows and changes.
        </Text>
        <Text as="p" mt={4}>
          Effective communication is at the heart of our approach. We work
          closely with you to ensure that our solutions are aligned with your
          vision and that all stakeholders are engaged and informed throughout
          the process. Just as with our core services, our “Other” offerings are
          designed to deliver value, reduce risks, and support the sustained
          success of your short-term rental business.
        </Text>
      </>
    ),
    capabilities: (
      <>
        <Flex direction="column">
          <Text
            fontSize="0.8rem"
            color={textBlack}
            opacity="0.33"
            fontWeight="500"
            textTransform="uppercase"
            letterSpacing="0.1px"
            fontFamily="DM Mono"
            m="0 0 0.25rem"
          >
            Including
          </Text>
          <List>
            <ListItem m="0 0 0.25rem">
              <Text
                fontSize="0.8rem"
                color={textBlack}
                fontWeight="500"
                textTransform="uppercase"
                letterSpacing="0.1px"
                fontFamily="DM Mono"
              >
                Custom Solutions
              </Text>
            </ListItem>
            <ListItem m="0 0 0.25rem">
              <Text
                fontSize="0.8rem"
                color={textBlack}
                fontWeight="500"
                textTransform="uppercase"
                letterSpacing="0.1px"
                fontFamily="DM Mono"
              >
                Niche Market Analysis
              </Text>
            </ListItem>
            <ListItem m="0 0 0.25rem">
              <Text
                fontSize="0.8rem"
                color={textBlack}
                fontWeight="500"
                textTransform="uppercase"
                letterSpacing="0.1px"
                fontFamily="DM Mono"
              >
                Specialized Training
              </Text>
            </ListItem>
            <ListItem m="0 0 0.25rem">
              <Text
                fontSize="0.8rem"
                color={textBlack}
                fontWeight="500"
                textTransform="uppercase"
                letterSpacing="0.1px"
                fontFamily="DM Mono"
              >
                Creative Marketing Strategies
              </Text>
            </ListItem>

            <ListItem m="0 0 0.25rem">
              <Text
                fontSize="0.8rem"
                color={textBlack}
                fontWeight="500"
                textTransform="uppercase"
                letterSpacing="0.1px"
                fontFamily="DM Mono"
              >
                Compliance Assistance
              </Text>
            </ListItem>

            <ListItem m="0 0 0.25rem">
              <Text
                fontSize="0.8rem"
                color={textBlack}
                fontWeight="500"
                textTransform="uppercase"
                letterSpacing="0.1px"
                fontFamily="DM Mono"
              >
                Technology Customization
              </Text>
            </ListItem>

            <ListItem m="0 0 0.25rem">
              <Text
                fontSize="0.8rem"
                color={textBlack}
                fontWeight="500"
                textTransform="uppercase"
                letterSpacing="0.1px"
                fontFamily="DM Mono"
              >
                + Many more
              </Text>
            </ListItem>
          </List>
        </Flex>
      </>
    ),
  },
];
