import React, { useState, useEffect } from "react";
import { Flex, Heading, Text, Icon, Box } from "@chakra-ui/react";
import Header from "../layout/Header";
import { textBlack, yellow } from "../utils/colors";
import CaseStudy from "../partials/CaseStudy";
import video1 from "../assets/video/video1.mp4";
import domesImage from "../assets/images/domes.jpg";
import domes from "../assets/icons/domes.svg";
import { Swiper, SwiperSlide } from "swiper/react";
import { useMediaQuery } from "react-responsive";
import { FaQuoteRight } from "react-icons/fa";
import author1 from "../assets/icons/author1.svg";
import lindenberg from "../assets/images/lindenberg.png";
import linderbergLogo from "../assets/icons/lindenberg.svg";
import author2 from "../assets/icons/author2.svg";
import { motion } from "framer-motion";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
// import "swiper/css/scrollbar";

// import required modules
import { Autoplay, Pagination, Navigation } from "swiper/modules";

// Example testimonials data
const testimonials = [
  {
    id: 1,
    video: video1,
    image: domesImage,
    logo: domes,
    logoSize: "5rem",
    bgColor: "#c699ff",
    text: "Strmaven's strategic approach significantly increased our bookings and refined our operations—exceptional service!",
    firstWord: "",
    secondWord: "25",
    thirdWord: "%",
    specialPadding: "1rem 1rem",
    resultsText: "Operational Efficiency: 20% reduction in costs.",
    author: author1,
  },
  {
    id: 2,
    video: "",
    image: lindenberg,
    logo: linderbergLogo,
    logoSize: "1rem",
    bgColor: "#81bcf9",
    text: "We share a set of values with Strmavens team that includes honesty, openness and integrity. We wanted to work with like-minded people. That’s exactly what we got.",
    firstWord: "",
    secondWord: "28",
    thirdWord: "%",
    specialPadding: "2rem 1.5rem",
    resultsText: "Revenue Boost: 28% increase in annual revenue.",
    author: author2,
  },
];

const CaseStudiesPage = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  const [progress, setProgress] = useState("0%");
  const [activeIndex, setActiveIndex] = useState(0); // Track active slide index

  useEffect(() => {
    setProgress("0%"); // Set initial progress to 0%
  }, []);

  const handleAutoplayTimeLeft = (swiper, time, progress) => {
    setProgress(`${(1 - progress) * 100}%`); // Reverse the progress
  };

  const handleSlideChange = (swiper) => {
    setActiveIndex(swiper.realIndex); // Update active index when slide changes
    setProgress("0%");
    setTimeout(() => {
      setProgress("100%");
    }, 0);
  };

  return (
    <>
      <Header />

      <Flex w="100%">
        <Flex w="100%" maxW="1280px" m="0 auto">
          <Flex w="100%" p="12rem 0 0" direction="column">
            <Heading
              fontSize={["5rem", "5rem", "6rem"]}
              color={textBlack}
              fontWeight="400"
              lineHeight="1.1"
              w={["100%", "100%", "initial"]}
              mr="1rem"
            >
              A long list of
              <br /> Happy Clients
            </Heading>

            <Flex w="100%" direction="column" m="8rem 0 0">
              <Box w="100%">
                <Flex w="100%" h="1px" bg={textBlack} />
                <Flex
                  w={progress}
                  h="3px"
                  bg={textBlack}
                  //   transition="width 0.9s ease"
                />
              </Box>
              <Flex w="100%" align="center" justify="space-between">
                <Text fontSize="1.125rem" fontWeight="500" lineHeight="1.5">
                  Featured
                </Text>
                <Flex align="center" justify="flex-end">
                  <Flex
                    w="1.5rem"
                    h="1.5rem"
                    border={`1px solid ${textBlack}`}
                    bg={activeIndex === 0 ? yellow : "transparent"}
                    borderRadius="50%"
                    align="center"
                    justify="center"
                  />
                  <Flex
                    w="1.5rem"
                    h="1.5rem"
                    border={`1px solid ${textBlack}`}
                    bg={activeIndex === 1 ? yellow : "transparent"}
                    borderRadius="50%"
                    align="center"
                    justify="center"
                  />
                </Flex>
              </Flex>

              <Swiper
                spaceBetween={30}
                slidesPerView={isMobile ? 1 : 1}
                loop={true}
                speed={900}
                centeredSlides={true}
                autoplay={{
                  delay: 6000,
                  disableOnInteraction: false,
                }}
                pagination={{
                  clickable: true,
                }}
                onAutoplayTimeLeft={handleAutoplayTimeLeft}
                onSlideChange={handleSlideChange}
                modules={[Autoplay, Pagination, Navigation]}
                className="testimonials-swiper"
              >
                {testimonials.map((testimonial) => (
                  <SwiperSlide key={testimonial.id}>
                    <CaseStudy
                      video={testimonial.video}
                      image={testimonial.image}
                      logo={testimonial.logo}
                      logoSize="5rem"
                      bgColor="#c699ff"
                      testimonial={testimonial.text}
                      firstWord={testimonial.firstWord}
                      secondWord={testimonial.secondWord}
                      thirdWord={testimonial.thirdWord}
                      specialPadding={testimonial.specialPadding}
                      resultsText={testimonial.resultsText}
                    />
                  </SwiperSlide>
                ))}
              </Swiper>

              <Flex w="100%" direction="column" m="8rem 0 0">
                <CaseStudy
                  video={video1}
                  image={domesImage}
                  logo={domes}
                  logoSize="5rem"
                  bgColor="#c699ff"
                  testimonial="“Strmaven's strategic approach significantly increased our bookings and refined our operations—exceptional service!“"
                  firstWord=""
                  secondWord="25"
                  thirdWord="%"
                  author={author1}
                  specialPadding="1rem 1rem"
                  resultsText="Operational Efficiency: 20% reduction in costs."
                />

                <CaseStudy
                  // video={video1}
                  image={lindenberg}
                  logo={linderbergLogo}
                  logoSize="1rem"
                  bgColor="#81bcf9"
                  testimonial="“We share a set of values with Strmavens team that includes honesty, openness and integrity. We wanted to work with like-minded people. That’s exactly what we got.“"
                  firstWord=""
                  secondWord="28"
                  thirdWord="%"
                  author={author2}
                  specialPadding="2rem 1.5rem"
                  resultsText="Revenue Boost: 28% increase in annual revenue."
                />
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};

export default CaseStudiesPage;
