import React, { useRef } from "react";
import { Flex, Heading, Stack, Text, Image, Box } from "@chakra-ui/react";
import { textBlack } from "../utils/colors";
import about1 from "../assets/icons/about1.svg";
import { motion, useScroll, useTransform } from "framer-motion";
import { StickyContainer, Sticky } from "react-sticky";

const About = () => {
  return (
    <>
      <Flex
        w="100%"
        align="center"
        justify="space-between"
        m="6rem 0 0"
        overflow="hidden"
      >
        <Flex w="50%"></Flex>
        <Flex w="50%">
          <Heading
            fontSize="2.875rem"
            fontWeight="400"
            lineHeight="1.1"
            letterSpacing=" -.03em"
          >
            For over a decade, businesses have trusted us to deliver innovative,
            independent solutions that align with their goals. At the heart of
            our consultancy is a commitment to excellence and the promise of
            driving sustainable success for every client we serve.
          </Heading>
        </Flex>
      </Flex>

      <StickyContainer>
        <Flex
          w="100%"
          maxW={["100%", "100%", "1280px"]}
          m="0 auto"
          direction="column"
        >
          <Flex w="100%" align="flex-start" justify="space-between">
            {/* <Flex w="50%" align="center" justify="center">
          <Image src={about1} />
        </Flex> */}
            <Flex w="50%">
              <Sticky topOffset={0} bottomOffset={-600}>
                {({ style, isSticky, wasSticky, distanceFromBottom }) => {
                  const isAtBottom = distanceFromBottom <= 0;
                  return (
                    <Image
                      src={about1}
                      style={{
                        ...style,
                        top: isSticky ? "6rem" : isAtBottom ? "0rem" : "6rem",
                        bottom: isAtBottom ? "0" : "auto",
                        left: "initial",
                        width: "600px",
                        position: isSticky
                          ? "fixed"
                          : isAtBottom
                          ? "relative"
                          : "relative",
                      }}
                    />
                  );
                }}
              </Sticky>
            </Flex>
            <Flex w="50%" direction="column">
              <Stack
                spacing="1rem"
                borderTop={`1px solid rgba(0,0,0,0.15)`}
                mt="6rem"
              >
                <Text
                  fontSize="2.875rem"
                  fontWeight="400"
                  m="2rem 0 0"
                  lineHeight="1.1"
                >
                  Our Mission
                </Text>
                <Text
                  fontSize="1.125rem"
                  fontWeight="500"
                  m="0"
                  lineHeight="1.5"
                >
                  To empower short-term rental businesses and individual hosts
                  by providing comprehensive, innovative, and virtual solutions
                  that enhance their operational efficiency, maximize bookings,
                  reduce costs, and elevate their market presence. Through
                  expert-driven strategies in property listing optimization,
                  social media management, branding, pricing, upselling,
                  accounting, and reputation management, we strive to be the
                  trusted partner that drives sustainable growth and success for
                  our clients.
                </Text>
              </Stack>

              <Stack
                spacing="1rem"
                borderTop={`1px solid rgba(0,0,0,0.15)`}
                mt="12rem"
              >
                <Text
                  fontSize="2.875rem"
                  fontWeight="400"
                  m="2rem 0 0"
                  lineHeight="1.1"
                >
                  Our Vision
                </Text>
                <Text
                  fontSize="1.125rem"
                  fontWeight="500"
                  m="0"
                  lineHeight="1.5"
                >
                  To be the leading virtual agency for short-term rental
                  businesses worldwide, recognized for our dedication to
                  transforming property management through cutting-edge digital
                  solutions, exceptional customer service, and a relentless
                  commitment to our clients' success. We envision a future where
                  every short-term rental operation is empowered with the tools
                  and insights needed to achieve unparalleled excellence and
                  profitability.
                </Text>
              </Stack>

              <Stack
                spacing="1rem"
                borderTop={`1px solid rgba(0,0,0,0.15)`}
                mt="12rem"
                mb="12rem"
              >
                <Text
                  fontSize="2.875rem"
                  fontWeight="400"
                  m="2rem 0 0"
                  lineHeight="1.1"
                >
                  Our Values
                </Text>
                <Text
                  fontSize="1.125rem"
                  fontWeight="500"
                  m="0"
                  lineHeight="1.5"
                >
                  No home grown consultants here…our team all have industry
                  experience, having held IT leadership positions in industry
                  for many years. We recognise the importance of being with you
                  throughout the whole lifecycle of concept to benefit
                  realisation and ensure that the same people who talk to you
                  upfront see the project through to fruition. As independent
                  market experts, we ensure that our advice is tailored
                  exclusively to benefit you. We are emotionally invested in
                  your success, by your success, blending advanced business
                  strategies with cutting-edge digital solutions.
                </Text>
              </Stack>
            </Flex>
          </Flex>
        </Flex>
      </StickyContainer>
    </>
  );
};

export default About;
