import React, { useState } from "react";
import {
  Flex,
  Heading,
  Image,
  Text,
  Icon,
  Button,
  IconButton,
  List,
  ListItem,
} from "@chakra-ui/react";
import {
  white,
  textBlack,
  primary,
  textPrimary,
  secondary,
  green,
  yellow,
} from "../utils/colors";
// import main3 from "../assets/images/main3.jpg";
// import TestimonialSwiper from "../components/partials/TestimonialSwiper";
// import mobilemain from "../assets/images/mobilemain.jpg";
import { useMediaQuery } from "react-responsive";
// import map from "../assets/icons/map.svg";
import { FaMinus, FaPlus } from "react-icons/fa";
import { services } from "../data/services"; // Import the services data

const Services = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const [activeService, setActiveService] = useState(null); // State to track the active service

  console.log("isMobile", isMobile);

  // console.log("containerwidth", window.innerWidth);

  const handleServiceClick = (id) => {
    setActiveService((prev) => (prev === id ? null : id)); // Toggle active state
  };

  return (
    <Flex
      // minH="100vh"
      bg={white}
      w="100%"
      align-="center"
      justify="center"
      p={["5rem 1.5rem 0", "5rem 1.5rem 0", "8rem 0rem 0"]}
      direction="column"
    >
      <Flex
        w="100%"
        maxW={["100%", "100%", "1280px"]}
        m="0 auto"
        direction="column"
      >
        <Flex direction="column" w="100%">
          <Heading
            fontSize={["2rem", "2rem", "2.75rem"]}
            color={textBlack}
            fontWeight="400"
            textAlign={["left", "left", "left"]}
            maxW={["100%", "100%", "100%"]}
            m={["0 ", "0 ", "0 auto"]}
          >
            We are technology consultants with a difference; we get things done.
            We offer independent advice with your best interests at heart,
            combining expertise, experience and commercial savvy to deliver your
            desired business outcomes.
          </Heading>
        </Flex>
      </Flex>

      <Flex w="100%" direction="column" m="6rem 0 0">
        {services.map((service, index) => (
          <Flex
            key={service.id}
            w="calc(100% + 6px)"
            borderTopLeftRadius="2rem"
            borderTopRightRadius="2rem"
            border={`3px solid ${textBlack}`}
            align="flex-start"
            justify="flex-start"
            borderBottom="none"
            // p="1.5rem 0 2rem"
            bg={activeService === service.id ? white : white}
            p=" 0 0 1.5rem"
            m="-1.5rem -3px 0"
            role="group"
            direction="column"
            zIndex={index + 1}
            position="relative"
            _hover={{
              bg: activeService === service.id ? white : service.color,
              cursor: "pointer",
            }}
            onClick={() => handleServiceClick(service.id)} // Handle click
          >
            <Flex w="100%" maxW="1280px" m="0 auto" p="2rem 0">
              <Flex w="50%">
                <Text
                  fontSize="0.8rem"
                  color={textBlack}
                  opacity="0.33"
                  fontWeight="500"
                  textTransform="uppercase"
                  letterSpacing="0.1px"
                  fontFamily="DM Mono"
                >
                  Capabilities /
                </Text>
              </Flex>

              <Flex
                w="50%"
                align="center"
                justify="space-between"
                // position="relative"
              >
                <Heading fontSize="2.875rem" fontWeight="400" color={textBlack}>
                  {service.title}
                </Heading>

                <IconButton
                  h="3.5rem"
                  w="3.5rem"
                  bg={white}
                  border={`1px solid ${textBlack}`}
                  borderRadius="10rem"
                  position="absolute"
                  right="1rem"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleServiceClick(service.id);
                  }} // Prevent event bubbling
                >
                  <Icon
                    as={activeService === service.id ? FaMinus : FaPlus}
                    fontWeight="300"
                    fontSize="0.6rem"
                  />
                </IconButton>
              </Flex>
            </Flex>

            <Flex
              w="100%"
              maxW="1280px"
              m="0 auto"
              maxH={activeService === service.id ? "100%" : "0px"} // Adjust max height
              overflow="hidden"
              transition="all 0.2s ease-in-out"
              _groupHover={{
                maxH: activeService === service.id ? "100%" : "3.5rem", // Hover adjustment
                transition: "all 0.2s ease-in-out",
              }}
              borderTop={
                activeService === service.id
                  ? `1px solid rgba(0,0,0,0.15)`
                  : "none"
              }
              p={activeService === service.id ? "3rem 0" : "0"}
            >
              <Flex w="50%">{service.capabilities}</Flex>

              <Flex w="50%">
                <Text fontSize="1.125rem" color={textBlack} fontWeight="500">
                  {service.text}
                </Text>
              </Flex>
            </Flex>
          </Flex>
        ))}

        <Flex
          // key={service.id}
          w="calc(100% + 6px)"
          borderTopLeftRadius="2rem"
          borderTopRightRadius="2rem"
          border={`3px solid ${textBlack}`}
          align="flex-start"
          justify="flex-start"
          borderBottom="none"
          // p="1.5rem 0 2rem"
          bg={white}
          p=" 0 0 1.5rem"
          m="-1.5rem -3px 0"
          role="group"
          direction="column"
          zIndex={99}
          position="relative"
        ></Flex>
      </Flex>
    </Flex>
  );
};

export default Services;
