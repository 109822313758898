import React from "react";
import { Flex, Grid, GridItem, Heading, Text } from "@chakra-ui/react";
import { textBlack, white } from "../utils/colors";

const Numbers = () => {
  return (
    <Flex
      w="100%"
      direction="column"
      p="0 0 6rem"
      bg={white}
      overflow="hidden"
      zIndex="900"
    >
      <Flex
        w="100%"
        maxW={["100%", "100%", "1280px"]}
        m="0 auto"
        direction="column"
      >
        <Flex
          w="100%"
          align="center"
          justify="space-between"
          borderTop={`3px solid ${textBlack}`}
        >
          <Flex w="50%">
            <Heading
              fontSize="4.125rem"
              fontWeight="400"
              lineHeight="1.1"
              letterSpacing=" -.03em"
            >
              In the numbers
            </Heading>
          </Flex>

          <Flex w="50%">
            <Text
              fontSize="1.125rem"
              fontWeight="500"
              m="2rem 0 0"
              lineHeight="1.5"
              mr="18%"
            >
              We are fueled by a powerful combination of strategic thinking and
              hands-on execution. Our team of pragmatic experts is dedicated to
              delivering tangible business benefits at every stage of our
              collaboration, ensuring you see measurable results with every step
              we take together.
            </Text>
          </Flex>
        </Flex>

        <Grid w="100%" templateColumns="repeat(3, 1fr)" gap="1rem" mt="6rem">
          <GridItem bg="#c699ff" borderRadius="1.5rem" p="1rem">
            <Flex direction="column" align="justify-between">
              <Text
                fontSize="6.25rem"
                fontWeight="400"
                lineHeight="1"
                mb="8rem"
              >
                78
              </Text>
              <Text fontSize="1.125rem" fontWeight="500">
                Current Active Clients 2024
              </Text>
            </Flex>
          </GridItem>
          <GridItem bg="#3be898" borderRadius="1.5rem" p="1rem">
            <Flex direction="column" align="justify-between">
              <Text
                fontSize="6.25rem"
                fontWeight="400"
                lineHeight="1"
                mb="8rem"
              >
                231
              </Text>
              <Text fontSize="1.125rem" fontWeight="500">
                Assignments Completed YTD
              </Text>
            </Flex>
          </GridItem>
          <GridItem bg="#fece00" borderRadius="1.5rem" p="1rem">
            <Flex direction="column" align="justify-between">
              <Text
                fontSize="6.25rem"
                fontWeight="400"
                lineHeight="1"
                mb="8rem"
              >
                93%
              </Text>
              <Text fontSize="1.125rem" fontWeight="500">
                Milestones Hit 2024 - YTD
              </Text>
            </Flex>
          </GridItem>
        </Grid>
      </Flex>
    </Flex>
  );
};

export default Numbers;
