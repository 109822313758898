import React, { useState, useEffect, useRef } from "react";
import { Helmet } from "react-helmet";
import { Flex, useBreakpointValue } from "@chakra-ui/react";
import { useMediaQuery } from "react-responsive";
// import Map from "../sections/Map";
import Cta from "../sections/Cta";
import Faq from "../sections/Faq";
// import ContactModal from "../components/modals/ContactModal";
import Header from "../layout/Header";
import Hero from "../sections/Hero";
import Services from "../sections/Services";
import HowItWorks from "../sections/HowItWorks";
import Testimonials from "../sections/Testimonials";
import Footer from "../layout/Footer";
import CaseStudies from "../sections/CaseStudies";
import About from "../sections/About";
import Numbers from "../sections/Numbers";

const Home = () => {
  const heroRef = useRef(null); // Create a ref for the Hero section
  const BenefitsRef = useRef(null); // Create a ref for the Benefits section
  const howItWorksRef = useRef(null); // Create a ref for the How It Works section
  const faqRef = useRef(null); // Create a ref for the FAQ section
  const [isOpen, setIsOpen] = useState(false);

  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  const scrollToHero = () => {
    heroRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const scrollToBenefits = () => {
    BenefitsRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const scrollToHowItWorks = () => {
    howItWorksRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const scrollToFaq = () => {
    faqRef.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <>
      <Header
        scrollToHowItWorks={scrollToHowItWorks}
        scrollToFaq={scrollToFaq}
        scrollToHero={scrollToHero}
        scrollToBenefits={scrollToBenefits}
      />

      {/* // MODAL START */}
      {/* <ContactModal isOpen={isOpen} setIsOpen={setIsOpen} isMobile={isMobile} /> */}
      {/* // MODAL END */}

      <Flex w="100%" direction="column" overflowX="hidden">
        <div ref={heroRef}>
          <Hero />
        </div>

        {/* <Benefits /> */}

        <div ref={BenefitsRef}>
          <Services isMobile={isMobile} />
        </div>

        <CaseStudies />

        <About />

        <Numbers />
      </Flex>

      <Footer />
    </>
  );
};

export default Home;
