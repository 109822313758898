import React, { useState } from "react";
import { Box, Flex, IconButton, Image, Text, Icon } from "@chakra-ui/react";
import { white } from "../utils/colors";
import { FaArrowRightLong } from "react-icons/fa6";

const CaseStudy = ({
  video,
  logo,
  logoSize,
  image,
  bgColor,
  testimonial,
  firstWord,
  secondWord,
  thirdWord,
  name,
  location,
  specialPadding,
  resultsText,
  author,
}) => {
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);

  const handleVideoLoaded = () => {
    setIsVideoLoaded(true);
  };

  return (
    <Flex
      w="100%"
      justify="space-between"
      gap="1.5rem"
      mb="1.5rem"
      maxH="500px"
    >
      <Box
        w="70%"
        position="relative"
        borderRadius="1.5rem"
        overflow="hidden"
        role="group"
      >
        <Image
          src={image}
          w="100%"
          h="100%"
          display={isVideoLoaded ? "none" : "block"}
          transition="all 0.3s"
          _groupHover={{
            transform: "scale(1.05)",
          }}
        />

        <Box
          as="video"
          src={video}
          autoPlay
          loop
          muted
          playsInline
          objectFit="cover"
          w="100%"
          h="100%"
          onCanPlayThrough={handleVideoLoaded}
          display={isVideoLoaded ? "block" : "none"}
          transition="all 0.3s"
          _groupHover={{
            transform: "scale(1.05)",
          }}
        />

        {/* <Box
          bg="rgba(0,0,0,0.5)"
          position="absolute"
          top="0"
          left="0"
          w="100%"
          h="100%"
          display={isVideoLoaded ? "none" : "block"}
        /> */}

        <Box
          position="absolute"
          top="0"
          right="0"
          bg={white}
          p={specialPadding}
          borderBottomLeftRadius="1rem"
          _before={{
            right: "100%",
            transform: "rotate(-90deg)",
            content: '""',
            position: "absolute",
            top: 0,
            width: "1em",
            height: "1em",
            background:
              "radial-gradient(circle at 0 0, transparent 1em, #fff 0)",
          }}
        >
          <Image src={logo} alt="Domes" h={logoSize} />
        </Box>
        <Box position="absolute" bottom="1rem" left="1rem">
          <Image src={author} h="3rem" />
          {/* <Text color="white" fontSize="1.125rem" fontWeight="400">
            {name}
          </Text>
          <Text color="white" fontWeight="500" fontSize="1.125rem">
            {location}
          </Text> */}
        </Box>

        <Box position="absolute" bottom="1rem" right="1rem">
          <IconButton
            size="lg"
            bg={white}
            borderRadius="10rem"
            //   border={`1px solid ${textBlack}`}
            _groupHover={{
              bg: "#1e50ff",
              color: white,
              border: `1px solid #1e50ff`,
              cursor: "pointer",
            }}
          >
            <Icon as={FaArrowRightLong} fontWeight="300" fontSize="0.75rem" />
          </IconButton>
        </Box>

        <Box
          position="absolute"
          top="0"
          left="0"
          bg="transparent"
          // top="50%"
          // left="50%"
          // transform="translate(-50%, -50%)"
          color="white"
          textAlign="left"
          p="1rem"
        >
          <Text fontSize="3rem" fontWeight="400" lineHeight="1.2" mr="20%">
            {testimonial}
          </Text>
        </Box>
      </Box>

      <Flex
        w="30%"
        bg={bgColor}
        borderRadius="1.5rem"
        direction="column"
        align="space-between"
        justify="space-between"
        minH="500px"
        p="1rem"
      >
        <Text fontSize="6.25rem" fontWeight="400">
          <Text as="span" fontSize="3rem" fontWeight="400">
            {firstWord}
          </Text>
          {secondWord}
          <Text as="span" fontSize="3rem" fontWeight="400">
            {thirdWord}
          </Text>
        </Text>
        <Text fontSize="1.125rem" fontWeight="500" mr="30%">
          {resultsText}
        </Text>
      </Flex>
    </Flex>
  );
};

export default CaseStudy;
